import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import ContentContainer from "../components/ContentContainer";
import CheckIcon from "../images/icon-check.svg";

const AccountDeleted = () => {

	return (
		<Layout>
			<SEO title="Account Deleted"/>
			<ContentContainer title="Account gelöscht!" subtitle="Schade, dass du gehst." half>
				<div className="text-center">
					<img className="icon50" src={ CheckIcon } alt="email confirmation successful"/>
					<h1 className="mt-4">Dein Account wurde erfolgreich gelöscht.</h1>
					<p>Du kannst das Fenster jetzt schließen oder zurück zu <Link to="/" className="color-orange">tinify.me</Link> gehen.</p>
				</div>
			</ContentContainer>
		</Layout>
	);
};

export default AccountDeleted;
